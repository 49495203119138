<template>
  <div class="feedback">
    <div class="feedback-content">
      <p class="title">反馈内容</p>
      <div class="content-type">
        <span
          :class="['typeLi', type_name == item ? 'typeLiSelect' : '']"
          v-for="item in typeList"
          :key="item"
          @click="type_name = item"
          >{{ item }}</span
        >
      </div>
      <p class="title">问题描述</p>
      <van-field
        v-model="content"
        rows="8"
        maxlength="200"
        show-word-limit
        type="textarea"
        :border="false"
        placeholder="请输入你的问题或者建议"
      />
      <p class="title">图片<span class="tips">（选填）</span></p>
      <van-uploader v-model="fileList" multiple :after-read="afterRead" accept="image/*" :before-delete="beforeDelete"/>
    </div>
    <div class="feedbackBtn" @click="submit">提交</div>
  </div>
</template>

<script>
import { addFeedBack } from "@/api/my";
import {uploadFiles} from "@/api/index"
export default {
  data() {
    return {
      typeList: [
        "订单类型",
        "功能类型",
        "技师类型",
        "服务类型",
        "项目类型",
        "其他"
      ],
      type_name: "",
      content: "",
      fileList: [],
      imgUrlList:[]
    };
  },
  methods: {
    // 选择图片回调
    afterRead(file) {
      uploadFiles({file:file.file}).then(res=>{
        this.imgUrlList.push(res.data)
      })
    },
    // 删除图片回调
    beforeDelete(e,val){
      this.imgUrlList.splice(val.index,1)
      return true
    },
    // 点击提交
    submit() {
      if (!this.type_name) {
        this.$toast("请选择反馈内容");
      } else if (!this.content) {
        this.$toast("请输入你的问题或者建议");
      } else {
        let data = {
          feedbackTypeName: this.type_name,
          img: this.imgUrlList.toString(),
          content: this.content
        };
        addFeedBack(data).then(res => {
          if (res.data) {
            this.$toast("反馈成功！");
            this.$router.go(-1);
          }
        });
      }
    }
  }
};
</script>
<style>
.feedback .feedback-content .van-uploader__preview:nth-child(4n) {
  margin-right: 0;
}
</style>
<style lang="less" scoped>
.feedback {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .feedback-content {
    padding: 0 0.3rem;
    flex: 1;
    .title {
      font-size: 0.4rem;
      margin: 0.4rem 0;
      .tips {
        color: grey;
      }
    }
    .content-type {
      display: flex;
      flex-wrap: wrap;
      .typeLi {
        width: 24.2%;
        text-align: center;
        border: solid 1px #e3e3e3;
        border-radius: 0.6rem;
        padding: 0.25rem 0;
        box-sizing: border-box;
        margin-bottom: 0.3rem;
        &:not(:nth-child(4n)) {
          margin-right: 1%;
        }
      }
      .typeLiSelect {
        border-color: rgb(235, 2, 2) red;
        background: rgb(235, 2, 2);
        color: #fff;
      }
    }
    .van-field {
      background: rgb(242, 242, 242);
      border-radius: 0.18rem;
    }
  }
  .feedbackBtn {
    position: sticky;
    bottom: 0.4rem;
    left: 0;
    margin: 0.4rem 0.3rem;
    background: rgb(235, 2, 2);
    padding: 0.4rem 0;
    font-size: 0.4rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    border-radius: 0.6rem;
  }
}
</style>
